.icon {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    fill: currentColor;
    -webkit-font-smoothing: antialiased;

    svg {
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
}

.icon--sm {
    width: 16px;
    height: 16px;
}

.icon--xsm {
    width: 20px;
    height: 20px;
}

.icon--stretch {
    width: inherit;
    height: inherit;
}
