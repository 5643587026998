@import '_reset.scss';
@import '_mixins.scss';
@import 'cosmos-variables.scss';
@import 'cosmos-native-variables.scss';
@import 'breakpoints.scss';
@import 'native-variables.scss';
@import 'scaffolding.scss';
@import 'typography/typography.reference.scss';
// @todo: figure out interesting bug - native variables can't find mixin if it goes before typography.reference
@import 'typography/typography.scss';

// A11Y - should be after reboot and variables, but before components
button {
    @extend %a11y-button-reset;

    cursor: pointer;
}

// Components
@import 'helpers.scss';
@import 'components/a11y-visually-hidden.scss';
@import 'components/a11y-stretched-touch.scss';
@import 'components/button.scss';
@import 'components/stack.scss';
@import 'components/icon.scss';
@import 'components/link.scss';
@import 'components/header.scss';
@import 'components/header-menu.scss';
@import 'components/header-mobile-menu.scss';
@import 'components/logotype.scss';
@import 'components/columns.scss';
@import 'components/card.scss';
@import 'components/cards.scss';
@import 'components/select.scss';
@import 'components/cta-box.scss';
@import 'components/carousel.scss';
@import 'components/page-footer.scss';
@import 'components/hero-image.scss';
@import 'components/page.scss';
@import 'components/page-section.scss';
@import 'components/social-links.scss';
@import 'components/app-links.scss';
@import 'components/skip-link.scss';
@import 'components/carousel-card.scss';
@import 'components/error-block.scss';
