@use "sass:math";

// also sync to lib/use-media-query.ts
$screens: (
        "desktop-lg":       1360px,
        "desktop-md":       1024px,
        "tablet-lg":         990px,
        "tablet-md":         900px,
        "navigation-full":   780px,
        "tablet-sm":         768px,
        "desktop-sm":        640px,
        "phone-lg":          412px
);


//
// Map of responsive properties
// By default we provide min / max values and screen size range within the scaling happens. Outside the range min and max values are used statically
// If necessary to provide some custom range, it can be added additionally. It's possible to omit one of values and widths:
//
// before-breakpoint: (
//    max-value: 100px,
//    max-width: desktop-md
// )
//
// after-breakpoint: (
//    min-value: 200px,
//    min-width: desktop-md
// )
//
// If you have a property that is static and changes only once or twice, it's better to use regular media mixin
// @include apply-screen-size(desktop-sm) { ... }
//

$breakpoints: (
        content-section-padding-horizontal: (
                default: (
                        min-value: 16px,
                        max-value: 108px,
                        min-width: phone-lg,
                        max-width: desktop-md
                )
        ),
        content-section-padding-vertical: (
                default: (
                        min-value: 64px,
                        max-value: 96px,
                        min-width: phone-lg,
                        max-width: desktop-md
                )
        ),
        content-section-gap-horizontal: (
                default: (
                        min-value: 16px,
                        max-value: 24px,
                        min-width: phone-lg,
                        max-width: desktop-md
                )
        ),
        content-section-gap-vertical: (
                default: (
                        min-value: 24px,
                        max-value: 32px,
                        min-width: phone-lg,
                        max-width: desktop-md
                )
        ),
        card-content-padding: (
                default: (
                        min-value: 24px,
                        max-value: 32px,
                        min-width: phone-lg,
                        max-width: desktop-md
                )
        ),
        header-min-height: (
                default: (
                        min-value: 64px,
                        max-width: desktop-md
                ),
                large: (
                        min-value: 72px,
                        min-width: desktop-md
                )
        )
);

@function resolve-width($value) {
    @if map-has-key($screens, $value) {
        @return map-get($screens, $value);
    }
    @return $value;
}

@function calculate-slope($min-value, $max-value, $min-width, $max-width) {
    @return math.div($max-value - $min-value, $max-width - $min-width);
}

@function calculate-y-axis-intersection($slope, $min-width, $min-value) {
    @return $min-value - $min-width * $slope;
}

@mixin responsive-property($property) {
    $breakpoints-for-property: map-get($breakpoints, $property);
    $previous-max: null;
    $breakpoint-keys: map-keys($breakpoints-for-property);

    @each $breakpoint, $config in $breakpoints-for-property {
        $min-value: map-get($config, min-value);
        $max-value: map-get($config, max-value);
        $min-width: resolve-width(map-get($config, min-width));
        $max-width: resolve-width(map-get($config, max-width));

        // Check if the breakpoint is 'default' or if it is the only breakpoint
        @if $breakpoint == 'default' or length($breakpoint-keys) == 1 {
            // If one value is missing, assume it's equal to the other
            @if $min-value == null {
                $min-value: $max-value;
            }
            @if $max-value == null {
                $max-value: $min-value;
            }

            @if $min-width and $max-width {
                @if $max-value > $min-value {
                    $slope: calculate-slope($min-value, $max-value, $min-width, $max-width);
                    $y-axis-intersection: calculate-y-axis-intersection($slope, $min-width, $min-value);
                    --#{$property}: clamp(
                            #{$min-value},
                            #{$y-axis-intersection} + #{$slope * 100}vw,
                            #{$max-value}
                    );
                } @else {
                    --#{$property}: #{$max-value};
                }
            } @else {
                @if $max-width == null {
                    @media (min-width: #{$min-width}) {
                        --#{$property}: #{$min-value};
                    }
                }

                @if $min-width == null {
                    @media (max-width: #{$max-width}) {
                        --#{$property}: #{$max-value};
                    }
                }
            }
        } @else {
            // If both values are missing, use the previous max value
            @if $min-value == null and $max-value == null {
                $min-value: $previous-max;
                $max-value: $previous-max;
            } @else {
                @if $min-value == null {
                    $min-value: $max-value;
                }
                @if $max-value == null {
                    $max-value: $min-value;
                }
            }

            // Generate responsive properties for each custom range
            @if $min-width and $max-width {
                $slope: calculate-slope($min-value, $max-value, $min-width, $max-width);
                $y-axis-intersection: calculate-y-axis-intersection($slope, $min-width, $min-value);
                @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
                    --#{$property}: clamp(
                            #{$min-value},
                            #{$y-axis-intersection} + #{$slope * 100}vw,
                            #{$max-value}
                    );
                }
            } @else {
                @if $min-width {
                    @media (min-width: #{$min-width}) {
                        --#{$property}: #{$min-value};
                    }
                }

                @if $max-width {
                    @media (max-width: #{$max-width}) {
                        --#{$property}: #{$max-value};
                    }
                }
            }
        }

        $previous-max: $max-value;
    }
}
