.app-links {
    --app-links-item-height: 40px;
    --app-links-google-aspect-ratio: 132 / 40;
    --app-links-google-width: calc(var(--app-links-item-height) * var(--app-links-google-aspect-ratio));
    --app-links-google-scale: 1.47;
    --app-links-apple-aspect-ratio: 120 / 40;
    --app-links-apple-width: calc(var(--app-links-item-height) * var(--app-links-apple-aspect-ratio));
    --app-links-gap: 8px;


    [lang="ja"] &,
    [lang="zh"] &,
    [lang="zh-Hant"] & {
        --app-links-apple-aspect-ratio: 108 / 40;
    }

    [lang="hi"] & {
        --app-links-apple-aspect-ratio: 109 / 41;
    }

    [lang="fr"] &,
    [lang="fr-ca"] & {
        --app-links-apple-aspect-ratio: 126 / 40;
    }

    [lang="ko"] & {
        --app-links-apple-aspect-ratio: 129 / 40;
    }

    [lang="fi"] & {
        --app-links-apple-aspect-ratio: 143 / 40;
    }

    [lang="hu"] & {
        --app-links-apple-aspect-ratio: 150 / 40;
    }

    [lang="tr"] & {
        --app-links-apple-aspect-ratio: 151 / 40;
    }

    [lang="de"] &,
    [lang="it"] &,
    [lang="pt"] &,
    [lang="pt-pt"] &,
    [lang="ru"] &,
    [lang="zh"] &,
    [lang="zh-Hant"] &,
    [lang="pl"] &,
    [lang="da"] &,
    [lang="nl"] &,
    [lang="nb"] &,
    [lang="fi"] &,
    [lang="sv"] &,
    [lang="hi"] &,
    [lang="ko"] &,
    [lang="ja"] &,
    [lang="fr"] &,
    [lang="fr-ca"] &,
    [lang="es-ar"] &,
    [lang="es-co"] &,
    [lang="es-mx"] &,
    [lang="es"] & {
        --app-links-google-scale: 1.28;
    }

    width: calc(var(--app-links-google-aspect-ratio) * var(--app-links-item-height) + var(--app-links-apple-width) + var(--app-links-gap));
    flex: 1 0 auto;
}

.app-links__description {
    @extend %apply-typography-p-3-responsive;

    overflow-wrap: break-word;
    margin-top: 8px;
    color: var(--color-text-subdued);
    font-size: 10px;
}

.app-links__list {
    display: flex;
    align-items: flex-start;
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 12px;
    justify-content: space-between;

    @include apply-screen-size(phone-lg) {
        justify-content: flex-start;
    }
}

.app-links__link {
    width: var(--app-links-apple-width);
    height: calc(100% / var(--app-links-apple-aspect-ratio));
    aspect-ratio: var(--app-links-apple-aspect-ratio);
    display: block;
    position: relative;
    overflow: hidden;
    flex: 1 0 auto;
    border-radius: 8px;
    background: #000;
    color: #fff;

    @include apply-screen-size(phone-lg) {
        flex: 0 0 auto;
    }

    > img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(var(--banner-scale, 1));
        transform-origin: center;
        width: auto;
        height: 100%;
    }
}

.app-links__link--google-play {
    --banner-scale: var(--app-links-google-scale);

    width: var(--app-links-google-width);
    height: calc(100% / var(--app-links-google-aspect-ratio));
    aspect-ratio: var(--app-links-google-aspect-ratio);
}
